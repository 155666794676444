import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/construction.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Construction Workers | Camelo"
        metaDescription="Schedule construction workers for multiple locations easily & record their hours accurately. Try Camelo for free today!"
        header="Schedule construction workers for multiple locations easily."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
